import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DirectionActivity } from 'src/app/models/portfolio/directionActivity.model';
import {AchievementGet, LKPortfolio} from 'src/app/models/portfolio/portfolio.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKPortfolioService {
  baseUrl = `${environment.apiEndpoint}${environment.portfolio.apiPaths.achievement}`;

  constructor(private http: HttpClient) { }
  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  /**
   * Документы
   * @param filter Фильтры
   */
  getAchievements(filter: { studentId?: string | null; directionActivityId?: string | null }): Observable<AchievementGet[]> {
    const query = this.CreateQuery(filter);

    return this.http.get<AchievementGet[]>(this.baseUrl, { params: query });
  }

  /**
   * Сохранение достижения
   * @param data Информауия о документе
   */
  postAchievement(data: FormData): Observable<string> {
    return this.http.post<string>(this.baseUrl, data);
  }

  /**
   * Обновление достижения
   * @param data Информауия о достижении
   */
  putAchievement(data: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data);
  }

  /**
   * Удаление достижения
   * @param id ID документа
   */
  deleteAchievement(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + id);
  }

  downloadAchievementFile(id: string) {
    return this.http.get(this.baseUrl + '/File/' + id, { responseType: 'blob' } )
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

  private CreateQuery(queryModel: any): HttpParams {
    let query = new HttpParams();
    for (const [key, value] of Object.entries(queryModel)) {
      // Value is array
      if(Array.isArray(value) && value != null && !value.includes('')) {
        query = query.appendAll({[key]: value});
      }
      // Value is object
      else if (value != null && !Array.isArray(value)) {
        query = query.append(key, `${value}`);
      }
    }
    return query;
  }
}
