import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import {DictDirectionActivity} from 'src/app/models/portfolio/directionActivity.model';
import {AchievementGet} from 'src/app/models/portfolio/portfolio.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKPortfolioService } from 'src/app/services/LKPortfolio/lkportfolio.service';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { NotificationsService } from 'src/app/services/Notifications/notifications.service';
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {DictService} from "../../services/LKPortfolio/dict.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openRemoveDialog} from "../../../helpers/dialog-helper";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  //#region VAR

  public studentId!: string;
  public filialId?: string;
  public loading = false;
  public editMode = false;
  public editable: boolean = true;

  //#region File

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.portfolio.maxFileSize,
    allowedExtensions: [".pdf"],
  };
  public maxFileSizeMessage: string = `Размер файла должен быть не более ${Math.round(environment.portfolio.maxFileSize/1024/1024)} МБ`;
  public fileUploaded = false;
  public fileUploadedList: { id: string, name: string }[] = [];
  public fileUploads?: Array<File>;

  //#endregion

  //#region Achievement

  /*public achievements: {
    directionActivityId: string,
    data: AchievementGet[]
  }[] = [];*/
  public achievements: AchievementGet[] = [];
  public directionActivities: DictDirectionActivity[] = [];
  public directionActivitiesByFilter: DictDirectionActivity[] = [];

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
    directionActivityId: new UntypedFormControl()
  });

  //#endregion

  //#region Student

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    filialId: "",
    studPerson: this.studPerson
  }

  //#endregion

  //#region Dialog

  public opened: boolean = false;

  //#endregion

  //#endregion

  constructor(private portfolioService: LKPortfolioService,
              private dictService: DictService,
              private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private studentService: LKStudentService) {
  }

  //#region Lifecycle methods

  async ngOnInit() {
    await this.getStudentByLogin();
    this.getDirectionActivities();
    this.getAchievements();
  }

  //#endregion

  //#region Data

  public async getStudentByLogin() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    await this.studentService.getCurrentStudent().toPromise().then(response => {
      if (!response) return;

      this.students = response;
      this.studentModel = changeStudent ? this.students[changeStudent-1] : this.students[0];
      this.studentId = this.studentModel.externalId;
      this.filialId = this.studentModel.filialId;
      this.getDirectionActivitiesByFilter();
    }, reason => {
      this.notificationService.showError('Не удалось загрузить информацию о студенте для просмотра');
    });
  }

  public getAchievements() {
    this.loading = true;
    this.portfolioService.getAchievements({ studentId: this.studentId }).subscribe({
      next: response => {
        if (response) this.achievements = response;
        /*this.achievements = [];

        if (response == null || response.length == 0) return;

        for (let i = 0; i < response.length; i++) {
          let achievement = this.achievements
            .find((_) =>
              _.directionActivityId == response[i].directionActivityId);

          if (achievement == null) {
            achievement = {
              directionActivityId: response[i].directionActivityId,
              data: []
            };
            this.achievements.push(achievement);
          }

          achievement.data.push(response[i]);
        }*/
      },
      error: err => {
        //this.notificationService.showError(err);
        console.log(err);
      },
      complete: () => this.loading = false
    });
  }

  public getDirectionActivities() {
    this.dictService.getDirectionActivities().subscribe({
      next: response => {
        this.directionActivities = response;
        this.getDirectionActivitiesByFilter();
      },
      error: err => {
        //this.notificationService.showError(err);
        console.log(err);
      }
    });
  }

  public getDirectionActivitiesByFilter() {
    this.directionActivitiesByFilter = this.directionActivities.filter((x: DictDirectionActivity) => x.filialId == this.filialId);
  }

  //#endregion

  //#region AchievementListView

  //#region Display

  public displayDirectionActivityName(id: string): string {
    let directionActivity = this.directionActivities
      .find((_) => _.id == id);

    return directionActivity?.name ?? "";
  }

  //#endregion

  //#region Handlers

  public removeAchievement({ dataItem }: any): void {
    if (!this.editable) return;

    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.name}`);

    dialog.result.subscribe((result ) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if (result.text == "Да") {
          this.portfolioService.deleteAchievement(dataItem.id).subscribe({
            next: () => {
              this.notificationService.showSuccess('Успешно')
              this.getAchievements();
            },
            error: err => this.notificationService.showError(err)
          })
        }
      }
    });
  }

  public editAchievement({ dataItem }: any): void {
    if (!this.editable) return;

    this.editMode = true;
    this.resetForm();

    //console.log(dataItem);

    this.editForm = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      directionActivityId: new FormControl(dataItem.directionActivityId)
    });

    this.fileUploadedList = dataItem.files;
    this.fileUploaded = dataItem.files?.length > 0;
  }

  //#endregion

  //#endregion

  //#region Download

  public downloadFile(file: { id: string, name: string }) {
    this.portfolioService.downloadAchievementFile(file.id).subscribe({
      next: response => {
        let blob:any = new Blob([response], { type: `application/pdf; charset=utf-8` });

        saveAs(blob, file.name);
      },
      error: err => this.notificationService.showError(err.status == 404 ? 'Файл не найден' : err)
    });
  }

  //#endregion

  //#region EditForm

  public addAchievement() {
    if (!this.editable) return;

    this.editMode = true;
    this.resetForm();
  }

  public closeEditForm() {
    this.editMode = false;
    this.resetForm();
  }

  public saveAchievement() {
    if (!this.editable) return;

    //console.log(this.editForm.value);
    //console.log(this.fileUploads);

    let formData = new FormData();

    if (this.editForm.value.id) formData.append(`Id`, this.editForm.value.id);
    formData.append(`Name`, this.editForm.value.name);
    formData.append(`StudentId`, this.studentId);
    formData.append(`DirectionActivityId`, this.editForm.value.directionActivityId);

    this.fileUploads?.forEach((_) => {
      formData.append(`Files`, _, _.name);
    });

    this.editForm.value.id
      ? this.portfolioService.putAchievement(formData).subscribe({
        next: () => {
          this.getAchievements();
          this.closeEditForm()
          this.notificationService.showSuccess("Обновлено");
        },
        error: err => this.notificationService.showError(err)
      })
      : this.portfolioService.postAchievement(formData).subscribe({
        next:() => {
          this.getAchievements();
          this.closeEditForm()
          this.notificationService.showSuccess("Сохранено");
        },
        error:err => this.notificationService.showError(err)
      });

    //this.editMode = false;
    //this.resetForm();
  }

  public resetForm() {
    this.fileUploaded = false;
    this.fileUploadedList = [];
    this.fileUploads = [];
    this.editForm.reset();
  }

  //#region File

  public fileSelected() {
    this.fileUploaded = true;
  }

  public fileRemoved() {
    this.fileUploaded = false;
  }

  //#endregion

  //#endregion

  //#region Dialog

  public openDialog() {
    this.opened = true;
  }

  public closeDialog() {
    this.opened = false;
  }

  //#endregion
}
