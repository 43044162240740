<div class="progress">
    <kendo-tilelayout>
        <kendo-tilelayout-item>
            <kendo-tilelayout-item-body>
                <form novalidate class="k-form" [formGroup]="semesterForm">
                    <div class="dropdownBlock">
                        <div class="dropdownChild">
                            <h2 class="dropdownTitle">Выбрать период</h2>
                        </div>
                        <div class="dropdownChild">
                            <kendo-dropdownlist
                            [data]="studentSemesters"
                            textField="fullCourseName"
                            (valueChange)="valueChange($event)"
                            valueField="semestrNum"
                            style="width: 356px"
                            [valuePrimitive]="true"
                            [(ngModel)]="lastSemester"
                            [formControl]="$any(semesterForm).controls['semestrNum']"
                            formControlName="semestrNum" #semestrNum required>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </form>
                <!-- Промежуточный контроль -->
                <h2 class="progress__title">Промежуточный контроль</h2>
                <kendo-grid
                [data]="middleControls"
                [loading]="loading"
                scrollable="none">
                    <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Дисциплина" [width]="200">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">
                                {{dataItem.disciplineName + ' (' + dataItem.controlActionName + ')'}}
                            </span>
                        </ng-template>

                    </kendo-grid-column>
                    <kendo-grid-column-group title="Сессия" headerClass="grid-header">
                        <kendo-grid-column headerClass="grid-header" field="markView" title="Оценка" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.markView}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" [hidden]="true" field="ratingScore" title="Балл рейтинга" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.ratingScore}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" field="markDate" title="Дата получения" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.markDate | date:'dd.MM.YYYY':'GMT'}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" field="retakes" title="Пересдачи" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.retakeCount}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid-column-group>
                </kendo-grid>
                <!-- Текущий контроль -->
                <div *ngIf="!hideCurrentControl">
                    <h2 class="progress__title">Текущий контроль</h2>
                    <kendo-grid [kendoGridBinding]="currentControls"
                    [loading]="currentControlLoading"
                    scrollable="none">
                        <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Дисциплина" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell leftAlignment">
                                    {{dataItem.disciplineName + ' (' + dataItem.controlActionName + ')'}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column-group headerClass="grid-header" *ngFor="let item of dictAttestations" [title]="item.name">
                            <kendo-grid-column headerClass="grid-header" field="ball" title="Оценка" [width]="80" format="n0">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell centerAlignment">
                                        {{currentControlMarks(item.externalId, dataItem, 'ball')}}
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column headerClass="grid-header" field="hoursAbsent" title="Пропущенные часы" [width]="80" format="n0">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell centerAlignment" >
                                        {{currentControlMarks(item.externalId, dataItem, 'hoursAbsent')}}
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid-column-group>
                    </kendo-grid>
                </div>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>
