export const breadcrumbItemsMap = new Map<string, string>([
  ["", "Личный кабинет студента"],
  ["studyProgress","Успеваемость"],
  ["education", "Образование"],
  ["portfolio", "Портфолио"],
  ['switchuser', 'Просмотр от другого лица'],
  ['alert', "Объявления и новости"],
  ['show',"Объявления и новости"],
  ['electroresources', "Электронные образовательные ресурсы и ЭБС"],
])
