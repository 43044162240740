import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { Location } from '@angular/common'
import { Observable } from 'rxjs';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { SwitchUserService } from 'src/app/services/switchuser/switch-user.service';
import { TokenStorageService } from 'src/app/services/token.service';
import { Router } from '@angular/router';
import { tokenStore } from 'src/options/settings';

@Component({
  selector: 'switchuser',
  templateUrl: './switchuser.component.html',
  styleUrls: ['./switchuser.component.scss']
})
export class SwitchuserComponent implements OnInit {

  public currentToken = "";
  public students: LKStudent[] = [];
  public formGroup: FormGroup = new FormGroup({
    studentId: new FormControl('', Validators.required),
  });
  public virtual: any = {
    itemHeight: 28,
  };

  public studEduGroups: LKStudEduGroup[] = [];
  public studPlans: LKStudPlan[] = [];

  constructor(
    private location: Location,
    private studentService: LKStudentService,
    private studEduGroupService: LKStudEduGroupService,
    private studPlanService: LKStudPlanService,
    private switchUserService: SwitchUserService,
    private tokenService: TokenStorageService,
    private router: Router) { }

  ngOnInit(): void {
    this.getStudEduGroups();
    this.currentToken = this.tokenService.getToken();
  }

  public switchStudent() {
    const filter = this.studEduGroups.filter((x) => x.studentId === this.formGroup.value.studentId);
    const studentId = filter[0].studentId;
    const studPersonId = filter[0].studPersonId;
    this.switchUserService.switchStudent(studPersonId, studentId)
      .subscribe(
        response => {
          localStorage.setItem('currentToken', JSON.stringify(this.currentToken));
          localStorage.setItem('switchStudent', 'true');
          localStorage.removeItem(tokenStore);
          localStorage.setItem(tokenStore, JSON.stringify(response));
          this.router.navigate(['/home/'])
          .then(() => {
            window.location.reload();
          });
        }
      );
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  // Get stud edugroups
  public getStudEduGroups() {
    this.studEduGroupService.getAllStudEduGroups()
    .subscribe(
      async response => {
        this.studEduGroups = response;
        this.studEduGroups = this.studEduGroups.sort((a, b) => a.fullName.localeCompare(b.fullName));
      }
    );
  }

  onCancel(): void {
    this.location.back()
  }

}
